import React from 'react'
import PostItem from '../post-item';
export default function Hit({ hit }) {
  return (
    <PostItem
      slug={hit.fields.slug}
      title={hit.title}
      description={hit.description}
    />
  )
}
