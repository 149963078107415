import React from 'react'
import Search from '../components/search'
import SEO from '../components/seo'
import Global from '../styles/global'

export default function SearchPage() {
  return (
    <>
      <SEO title="Search" />
      <Global />
      <Search />
    </>
  )
}
