import styled from "styled-components"
import { colorPalette } from "../../styles/colorSchema"

export const SearchWrapper = styled.section`
  display:flex;
  flex-direction: column;
  justify-content:center;
  transition: opacity 0.4s;

  .ais-InstantSearch__root {
    display:flex;
    flex-direction: column;
    height: auto;
    width: 100%;
  }
  /* .ais-SearchBox,
  .ais-Stats {
   
    padding: 0.5rem 3rem;
  } */
  .ais-SearchBox {
    margin:0 100px 0 100px;
    @media(max-width:764px){
      margin:0 10px 0 10px;
    }
    padding: 2rem;
  }
  
  .ais-SearchBox-input {
    background: ${colorPalette.white};
    border-radius: 5px;
    border: 1px solid ${colorPalette.disabledColor};
    color: ${colorPalette.black};
    
    font-size: 1.6rem;
    padding: 0.5rem;
    width: 100%;
    margin-bottom:25px;
    
    &::placeholder {
      color: ${colorPalette.disabledColor};
    }
  }
  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    display: none;
  }
`;
export const Container = styled.header`
  /* display:flex;
  flex-direction:column;
  justify-content:center; */
  height: 220px;
  background-color:${colorPalette.primary};
`;

export const Wrapper = styled.div`
  display:flex;
  padding-top:30px;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  color: #000;

  li{
    list-style:none;
  }
  text{
  }
`;
export const SubTitle = styled.text`
  display:flex;
  color:${colorPalette.white};
  justify-content:center;
  padding-top:25px;
  font-weight:${({ bold }) => bold ? bold : 'normal'};
  font-size:18px;
  @media (min-width:468px){
    font-size:36px;
  }
`;
