import React from 'react'
import { InstantSearch, SearchBox, Hits, Stats } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { Container, SearchWrapper, SubTitle, Wrapper } from './styled';
import Hit from './Hit';
import NavBar from '../nav-bar';
import { useSelector } from 'react-redux';

const algolia = {
  appId: process.env.GATSBY_ALGOLIA_APP_ID,
  searchApiKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  indexName: process.env.GATSBY_ALGOLIA_INDEX_NAME
}
const searchClient = algoliasearch(algolia.appId, algolia.searchApiKey);
export default function Search() {
  const { stringSearch } = useSelector(state => state.app);
  return (
    <Container>
      <NavBar />
      <SubTitle>
        <text>Como podemos <b>ajudar</b> ?</text>
      </SubTitle>
      <SearchWrapper>
        <InstantSearch searchClient={searchClient} indexName={algolia.indexName}>
          <SearchBox defaultRefinement={stringSearch} showLoadingIndicator={true} translations={{ placeholder: "Pesquisar..." }} />
          <Wrapper>
            <Stats translations={{
              stats(nbHits) {
                return `${nbHits} Resultados encontrados`
              }
            }} />
            <Hits hitComponent={Hit} />
          </Wrapper>
        </InstantSearch>
      </SearchWrapper>
    </Container>
  )
}
